import Vue from 'vue'
import App from '@/App.vue'
import BootstrapVue, {
  BAlert,
  BAvatar,
  BAspect,
  BBadge,
  BButton,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BImg,
  BFormCheckbox,
  BTable,
  BNav,
  BNavItem,
  BNavItemDropdown,
  BNavbarToggle,
  BNavbarBrand,
  BNavbar,
  BNavForm,
  BNavText,
  BNavbarNav,
  BCollapse,
  ModalPlugin,
} from 'bootstrap-vue'
import { VueFormBuilderPlugin } from 'v-form-builder'
import VueRouter from 'vue-router'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import OneSignalVue from 'onesignal-vue'
// eslint-disable-next-line import/no-named-as-default
import VueDatePicker from '@mathieustan/vue-datepicker'

// import { defineCustomElements } from '@ionic/pwa-elements/loader'

import i18n from '@/locales/i18n.js'
import router from '@/router/route.js'
import store from '@/store'
import '@/filters/filter.js'
import modalMixin from '@/mixins/modalMixin.js'
// import "@/onesignal.js"

// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/main.css'
import '@/assets/scss/custom.scss'
import 'remixicon/fonts/remixicon.css'

Vue.config.productionTip = false
process.env.VUE_APP_ENVIROMENT === 'local' ? (Vue.config.devtools = true) : (Vue.config.devtools = false)

Vue.use(VueFormBuilderPlugin, {
  controls: {
    image: {
      name: 'Image Upload',
      description: 'Image Upload Field',

      /**
       * Control View Mapping
       */
      fieldComponent: () => import('@/library/vueformbuilder/ImageControl.vue'),

      /**
       * Control Configuration View Mapping
       */
      configComponent: () => import('@/library/vueformbuilder/ImageConfigView.vue'),

      /**
       * Control-Data Extend
       * Your specific data for your custom control
       */
      configData: {
        isSelfie: false,
        accept: 'image/jpg,image/jpeg,image/png',
        versionId: null,
      },

      /**
       * Default data of the Switch in Renderer
       * @returns {boolean}
       */
      rendererDefaultData() {
        return ''
      },
    },
    datepicker: {
      name: 'Date Picker',
      description: 'Custom Date Picker',

      /**
       * Control View Mapping
       */
      fieldComponent: () => import('@/library/vueformbuilder/DatePickerControl.vue'),

      /**
       * Control Configuration View Mapping
       */
      configComponent: () => import('@/library/vueformbuilder/DatePickerConfigView.vue'),

      /**
       * Control-Data Extend
       * Your specific data for your custom control
       */
      configData: {},

      /**
       * Default data of the Switch in Renderer
       * @returns {boolean}
       */
      rendererDefaultData() {
        return ''
      },
    },
    picture: {
      name: 'Image Preview',
      description: 'Image Preview Field',

      /**
       * Control View Mapping
       */
      fieldComponent: () => import('@/library/vueformbuilder/PictureControl.vue'),

      /**
       * Control Configuration View Mapping
       */
      configComponent: () => import('@/library/vueformbuilder/PictureConfigView.vue'),

      /**
       * Control-Data Extend
       * Your specific data for your custom control
       */
      configData: {
        imageUrl: null,
      },

      /**
       * Default data of the Switch in Renderer
       * @returns {boolean}
       */
      rendererDefaultData() {
        return ''
      },
    },
  },
})
Vue.use(VueDatePicker, { lang: 'en' })
Vue.use(VueRouter)
Vue.use(VueGoogleMaps, {
  load: {
    key:
      process.env.VUE_APP_ENVIROMENT !== 'local'
        ? 'AIzaSyAPzGMfR1W9BwQ9ait21UtJe2nl6dhUIUw'
        : 'AIzaSyDscfUBhufQHw0IiSl2Hz1JadPFsZy9Q8k',
    libraries: 'places',
    region: 'TH',
    language: 'my',
  },
})
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(OneSignalVue)

Vue.mixin(modalMixin)

Vue.use(ModalPlugin)
Vue.component('BAlert', BAlert)
Vue.component('BAspect', BAspect)
Vue.component('BBadge', BBadge)
Vue.component('BButton', BButton)
Vue.component('BCard', BCard)
Vue.component('BCol', BCol)
Vue.component('BContainer', BContainer)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BForm', BForm)
Vue.component('BFormInput', BFormInput)
Vue.component('BImg', BImg)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BAvatar', BAvatar)
Vue.component('BTable', BTable)
Vue.component('BNav', BNav)
Vue.component('BNavItem', BNavItem)
Vue.component('BNavItemDropdown', BNavItemDropdown)
Vue.component('BNavbarToggle', BNavbarToggle)
Vue.component('BNavText', BNavText)
Vue.component('BNavbarNav', BNavbarNav)
Vue.component('BCollapse', BCollapse)
Vue.component('BNavbarBrand', BNavbarBrand)
Vue.component('BNavbar', BNavbar)
Vue.component('BNavForm', BNavForm)

export const bus = new Vue()

new Vue({
  i18n,
  store,
  router,
  beforeMount() {
    if (process.env.VUE_APP_ENVIROMENT !== 'local') {
      this.$OneSignal.init({
        appId: 'd863aae5-e036-426d-89bf-1f16571a5966',
        safari_web_id: 'web.onesignal.auto.20cc36d3-e742-47b9-8fc8-37c27a32926f',
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          actionMessage: this.$t('actionMessage'),
          acceptButton: this.$t('acceptButton'),
          cancelButton: this.$t('cancelButton'),
        },
        welcomeNotification: {
          disable: true,
          // title: this.$t("welcomeTitle"),
          // message: this.$t("welcomeMessage"),
          // url: ""
        },
      })
    }
  },
  render: (h) => h(App),
}).$mount('#app')

// Call the element loader after the platform has been bootstrapped
// defineCustomElements(window)
